<template>
  <el-main>
    <el-form class="el-form-search" label-width="100px">
      <el-form-item label="用户昵称：">
        <el-input v-model="searchForm.nickname" size="small"></el-input>
      </el-form-item>
      <el-form-item label="手机号码：">
        <el-input v-model="searchForm.mobile" size="small"></el-input>
      </el-form-item>
      <el-form-item label="企业类别：">
        <el-select v-model="searchForm.enterprise_type" size="small" placeholder="请选择">
          <el-option label="全部" :value="0"></el-option>
          <el-option label="工厂店" :value="1"></el-option>
          <el-option label="广告店" :value="2"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="服务类别：">
        <el-select v-model="searchForm.service_type" size="small" placeholder="请选择">
          <el-option label="全部" :value="0"></el-option>
          <el-option label="广告类" :value="1"></el-option>
          <el-option label="安装类" :value="2"></el-option>
          <el-option label="设计类" :value="3"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="用户来源：">
        <el-select v-model="searchForm.comefrom" size="small" placeholder="请选择">
          <el-option label="全部" :value="0"></el-option>
          <el-option label="微信" :value="1"></el-option>
          <el-option label="支付宝" :value="2"></el-option>
          <el-option label="快应用" :value="3"></el-option>
          <el-option label="抖音" :value="4"></el-option>
          <el-option label="QQ" :value="5"></el-option>
          <el-option label="百度" :value="6"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="注册时间：">
        <el-date-picker
          v-model="searchForm.start_time"
          size="small"
          type="daterange"
          value-format="timestamp"
          range-separator="~"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
        ></el-date-picker>
      </el-form-item>
      <el-form-item label="所属区域：">
        <el-cascader
          v-model="searchForm.city_id"
          :props="{ value: 'id', label: 'name', children: '_child' }"
          placeholder="请选择或搜索"
          clearable
          filterable
          :options="areaList"
          size="small"
        ></el-cascader>
      </el-form-item>
      <el-form-item label="末位淘汰：">
        <el-select v-model="searchForm.out" size="small" placeholder="请选择">
          <el-option label="全部" :value="0"></el-option>
          <el-option label="1%" :value="1"></el-option>
          <el-option label="3%" :value="3"></el-option>
          <el-option label="5%" :value="5"></el-option>
          <el-option label="10%" :value="10"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label=" " label-width="25px">
        <el-button type="primary" size="small" @click="search">搜索</el-button>
        <el-button type="text" size="small" @click="cancelSearch">清空搜索条件</el-button>
      </el-form-item>
    </el-form>
    <el-table :data="list" :header-cell-style="{ 'background-color': '#F8F9FA' }" row-key="id">
      <template slot="empty">
        <No />
      </template>
      <el-table-column prop="nickname" label="用户信息" width="200" align="center">
        <template v-slot="{ row }">
          <userInfo :name="row.nickname" :avatar="row.avatar"></userInfo>
        </template>
      </el-table-column>
      <el-table-column prop="type" label="企业/个人" width="200" align="center">
        <template v-slot="{ row }">{{ row.type == 1 ? '企业' : '个人' }}</template>
      </el-table-column>
      <el-table-column prop="enterprise_type" label="企业类别" align="center">
        <template v-slot="{ row }">{{ row.enterprise_type == 1 ? '工厂店' : '广告店' }}</template>
      </el-table-column>
      <el-table-column prop="service_type" label="服务类别" align="center">
        <template v-slot="{ row }">{{ row.service_type == 1 ? '广告类' : row.service_type == 2 ? '安装类' : '设计类' }}</template>
      </el-table-column>
      <el-table-column prop="city_name" label="所属区域" align="center"></el-table-column>
      <el-table-column prop="mobile" label="会员手机号" width="140" align="center"></el-table-column>
      <el-table-column prop="withdraw_rate" label="提现费率" width="120" align="center">
          <template v-slot="{ row }">{{ row.withdraw_rate }}%</template>
      </el-table-column>
      <el-table-column prop="service_sales_amount" label="累计服务订单金额" width="150" align="center"></el-table-column>
      <el-table-column prop="order_count" label="累计服务订单数" width="150" align="center"></el-table-column>
      <el-table-column prop="average_score" label="服务平均分" width="100" align="center"></el-table-column>
      <el-table-column prop="comefrom" label="来源" align="center">
        <template v-slot="{ row }">
          {{ row.comefrom == 1 ? '微信' : row.comefrom == 2 ? '支付宝' : row.comefrom == 3 ? '快应用' : row.comefrom == 4 ? '抖音' : row.comefrom == 5 ? 'QQ' : '百度' }}
        </template>
      </el-table-column>
      <el-table-column prop="status" label="状态" align="center">
        <template v-slot="{ row }">{{ row.status == 1 ? '正常' : '淘汰' }}</template>
      </el-table-column>
      <el-table-column prop="create_time" label="注册时间" width="150" align="center">
        <template v-slot="{ row }">{{ getDateformat(row.create_time) }}</template>
      </el-table-column>
      <el-table-column label="操作" min-width="100" align="center" fixed="right">
        <template v-slot="{ row }">
          <el-button v-if="row.status == 1" @click="$router.push({ path: '/user/equityMemberInfo', query: { id: row.id } })" type="text" size="small">详情</el-button>
          <el-button v-if="row.status == 1" @click="eliminate(row)" type="text" size="small">淘汰</el-button>
        </template>
      </el-table-column>
    </el-table>
    <Paging :total="total_number" :page="searchForm.page" :pageNum="searchForm.rows" @updatePageNum="updateData"></Paging>
  </el-main>
</template>

<script>
import _ from 'lodash';
import userInfo from '@/components/userInfo';
import Paging from '@/components/paging';
import { getDateformat } from '@/util/getDate';
export default {
  components: {
    userInfo,
    Paging,
  },
  data() {
    return {
      searchForm: {
        page: 1,
        rows: 10,
        nickname: '',
        mobile: '',
        enterprise_type: 0,
        service_type: 0,
        comefrom: 0,
        start_time: '',
        end_time: '',
        city_id: [],
        out: 0,
      },
      areaList: [],
      list: [],
      total_number: 0,
    };
  },
  created() {
    this.getList();
    this.getArea();
  },
  methods: {
    search() {
      this.searchForm.page = 1;
      this.getList();
    },
    cancelSearch() {
      this.searchForm = {
        page: 1,
        rows: 10,
        nickname: '',
        mobile: '',
        enterprise_type: 0,
        service_type: 0,
        comefrom: 0,
        start_time: '',
        end_time: '',
        city_id: [],
        out: 0,
      };
      this.getList();
    },
    updateData(val, status) {
      if (status == 0) {
        this.searchForm.rows = val;
        this.getList();
      } else {
        this.searchForm.page = val;
        this.getList();
      }
    },
    getDateformat(val) {
      return getDateformat(val);
    },
    getList() {
      let searchForm = _.cloneDeep(this.searchForm);
      if (searchForm.city_id.length) {
        searchForm.city_id.length > 1 ? (searchForm.city_id = searchForm.city_id[1]) : (searchForm.city_id = searchForm.city_id[0]);
      } else {
        searchForm.city_id = '';
      }
      if (searchForm.start_time) {
        searchForm.end_time = searchForm.start_time[1] / 1000;
        searchForm.start_time = searchForm.start_time[0] / 1000;
      }
      this.$axios.post(this.$api.samecity.rights.samecityMemberList, searchForm).then(res => {
        if (res.code == 0) {
          this.list = res.result.list;
          this.total_number = res.result.total_number;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    eliminate(row) {
      this.$confirm('请确认是否淘汰？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        this.$axios
          .post(this.$api.samecity.rights.eliminateMember, {
            id: row.id,
          })
          .then(res => {
            if (res.code == 0) {
              row.status = 0;
              this.$message.success('已成功淘汰');
            } else {
              this.$message.error(res.msg);
            }
          });
      });
    },
    getArea() {
      this.$axios.get(this.$api.set.area, { tree: 1, level: 2 }).then(res => {
        if (res.code == 0) {
          let list = res.result.list;
          for (let i in list) {
            for (let y in list[i]._child) delete list[i]._child[y]._child;
          }
          this.areaList = list;
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.el-main {
  background: #fff;
}
</style>